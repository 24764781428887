<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Empresa" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" exportFilename="relatorio[EMPRESAS]" csvSeparator=";" :value="empresas"
          v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
              <h5 class="m-0">Gerenciar Empresas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="razao_social" header="Razão Social" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">razao_social</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="cnpj" header="CNPJ" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title"></span>
              {{ slotProps.data.cnpj }}
            </template>
          </Column>


          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarEmpresaModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Gerenciar Empresa" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="razao_social">Razão Social</label>
            <InputText id="razao_social" v-model.trim="empresa.razao_social" required="true" autofocus
              :class="{ 'p-invalid': submitted && !empresa.razao_social }" />
            <small class="p-invalid" v-if="submitted && !empresa.razao_social">Razão Social é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">CNPJ</label>
            <InputMask id="name" v-model.trim="empresa.cnpj" required="true" autofocus mask="99.999.999/9999-99"
              :class="{ 'p-invalid': submitted && !empresa.cnpj }" />
            <small class="p-invalid" v-if="submitted && !empresa.cnpj">CNPJ é obrigatório.</small>
          </div>
          <div class="field">
            <label for="email">E-mail</label>
            <InputText id="email" type="email" v-model.trim="empresa.email" required="true" autofocus
              :class="{ 'p-invalid': submitted && !empresa.email }" />
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarEmpresa" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;display:block;" />
            <span style="display:block;" v-if="empresa">Apagar: <b>{{ empresa.razao_social }}</b>?</span>
          </div>
          <template #footer class="align-items-center ">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarEmpresa" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="empresas">Apagar empresas selecionadas?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      empresas: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      empresa: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarEmpresa();
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {
          console.log(response);
          this.empresas = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    trocarStatus() {
      this.empresa.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.empresa = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarEmpresa() {
      this.submitted = true;
      if (this.empresa.id) {
        this.axios
          .post("/empresas/editar", this.empresa)
          .then(() => {
            this.listarEmpresa();
            this.productDialog = false;
            this.empresa = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/empresas/adicionar", this.empresa)
          .then(() => {
            this.listarEmpresa();
            this.productDialog = false;
            this.empresa = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(empresa) {
      this.productDialog = true;

      this.empresa = { ...empresa };
      if (this.empresa.status == 1) {
        this.status = { label: "Ativo", value: "1" };
      } else {
        this.status = { label: "Inativo", value: "0" };
      }
    },
    apagarEmpresaModal(empresa) {
      this.empresa = empresa;
      this.deleteProductDialog = true;
    },
    apagarEmpresa() {
      this.axios
        .post("/empresas/apagar", this.empresa)
        .then(() => {
          this.listarEmpresa();
          this.deleteProductDialog = false;
          this.empresa = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Empresa apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {

      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {

        this.axios
          .post("/empresas/apagar", obj[key])
          .then(() => {
            this.listarEmpresa();
            this.deleteProductDialog = false;
            this.empresa = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Empresa apagada com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Erro, chama o t.i",
              life: 3000,
            });
          });
      });


    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
